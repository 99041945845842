<template>
  <header id="header" class="header">
    <div class="inner">
      <img src="../assets/the-excellence-partnership.png" class="logo" alt="The Excellence Partnership" />

      <nav class="menu" :class="{ open: showMenu }">
        <ul>
          <li v-for="menu_page in mainMenu" :key="menu_page.ItemID">
            <router-link to="/" @click.native="scrollFix('#' + menu_page.ItemID)">{{ menu_page.name }}</router-link>
          </li>
        </ul>
      </nav>
      <a @click.prevent="toggleMainMenu()" class="mobile-menu" href id="mobile-menu-btn">
        <font-awesome-icon icon="bars" v-show="!showMenu" />
        <font-awesome-icon icon="times" v-show="showMenu" />
      </a>
    </div>
  </header>
</template>

<script>
import { mapState, mapGetters } from "vuex"

var VueScrollTo = require("vue-scrollto")

var options = {
  container: "body",
  easing: "ease-in",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: function () {
    // scrolling started
  },
  onDone: function () {
    // scrolling is done
  },
  onCancel: function () {
    // scrolling has been interrupted
  },
  x: false,
  y: true
}

export default {
  data: function () {
    return {
      showMenu: false
    }
  },
  methods: {
    toggleMainMenu() {
      this.showMenu = !this.showMenu
      document.body.classList.toggle("fixed")
    },
    scrollFix: function (hash) {
      this.showMenu = false
      document.body.classList.remove("fixed")
      setTimeout(() => VueScrollTo.scrollTo(hash, 0, options), 500)
    }
  },
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["mainMenu"])
  }
}
</script>

<style scoped>
.header {
  padding: 4rem 0;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
}
.logo {
  width: 30rem;
}
.menu {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.menu ul,
.menu li {
  list-style-type: none;
}
.menu li {
  display: inline-block;
  padding: 0 3rem;
  font-size: 2.2rem;
}
.menu a {
  display: block;
  padding: 1rem 0;
  color: var(--primary-color);
  transition: all 0.3s;
  border-bottom: 1px solid transparent;
}
.menu a:hover,
.menu a.a-exact-active {
  border-bottom: 1px solid var(--primary-color);
}

@media screen and (max-width: 1350px) {
  .logo {
    width: 25rem;
  }
  .menu li {
    padding: 0 2rem;
    font-size: 2rem;
  }
}

@media screen and (min-width: 1091px) {
  .mobile-menu {
    display: none;
  }
}
@media screen and (max-width: 1090px) {
  .header {
    padding: 2rem 0;
  }
  .logo {
    width: 20rem;
  }
  .mobile-menu {
    display: block;
    position: absolute;
    right: 2%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 3rem;
    z-index: 11;
    color: var(--primary-color);
  }
  .menu {
    opacity: 0;
    position: fixed;
    pointer-events: none;
    bottom: 0;
    left: 0;
    right: auto;
    height: 100vh;
    width: 100%;
    transition: all 0.3s;
    background: white;
    z-index: 10;
    text-align: center;
  }
  .menu ul {
    display: block;
    top: 50%;
    width: 100%;
    position: absolute;
    transform: translateY(-50%);
  }
  .menu li {
    display: block;
    height: 10vh;
  }
  .menu a {
    border-bottom: none;
  }
  .menu a:hover,
  .menu a.a-exact-active {
    border-bottom: none;
    font-weight: bold;
  }

  .menu.open {
    opacity: 1;
    pointer-events: all;
  }
}
</style>
