<template>
  <section :id="page.ItemID" class="team content">
    <div class="inner">
      <h2>Case Studies</h2>
      <div class="grid">
        <router-link v-for="(case_study, i) in page.casestudies" :key="i" :to="case_study.url" class="link"
          ><img :alt="case_study.name" :src="case_study.image"/><span class="name">{{ case_study.name }}</span
          ><span class="project"></span
        ></router-link>
      </div>
      <div class="actions">
        <a href="#header" class="back">&lt; Back to Main Menu</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    page() {
      return this.$store.state.casestudiesPage || {}
    }
  }
}
</script>

<style scoped>
.team {
  color: var(--primary-color);
  background: white;
  padding-top: 8rem;
}
.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 6rem;
  row-gap: 5rem;
  margin: 4rem 0;
}

.actions {
  padding-top: 2rem;
  margin-bottom: 8rem;
}

.modal .actions {
  padding-top: 0;
  margin-bottom: 0;
}

.link img {
  width: 100%;
}

.link span {
  display: block;
}

.name {
  font-size: 1.8rem;
  margin-top: 1rem;
  font-weight: bold;
}

.project {
  line-height: 1.1;
  color: #333;
}

@media screen and (max-width: 1090px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4rem;
  }
  .project {
    font-size: 3rem;
  }
}
@media screen and (max-width: 600px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
  }
  .project {
    font-size: 2.5rem;
  }
  .actions {
    margin-bottom: 4rem;
  }
}
</style>
