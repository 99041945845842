<template>
  <div>
    <header-elem />
    <template v-if="!isService && !isCaseStudy">
      <intro-elem />
      <about-elem />
      <team-elem />
      <expertise-elem />
      <programme-elem />
      <services-elem />
      <case-studies-elem />
      <contact-elem />
    </template>
    <template v-if="isService">
      <service-elem />
    </template>
    <template v-if="isCaseStudy">
      <case-study-elem />
    </template>
    <footer-elem />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"

import api from "@/services/api"

import HeaderElem from "@/components/Header.vue"
import IntroElem from "@/components/Intro.vue"
import AboutElem from "@/components/About.vue"
import TeamElem from "@/components/Team.vue"
import ExpertiseElem from "@/components/Expertise.vue"
import ProgrammeElem from "@/components/Programme.vue"
import FooterElem from "@/components/Footer.vue"
import CaseStudiesElem from "@/components/CaseStudies.vue"
import CaseStudyElem from "@/components/CaseStudy.vue"
import ServicesElem from "@/components/Services.vue"
import ServiceElem from "@/components/Service.vue"
import ContactElem from "@/components/Contact.vue"

export default {
  name: "page",
  components: {
    HeaderElem,
    IntroElem,
    AboutElem,
    TeamElem,
    ExpertiseElem,
    ProgrammeElem,
    CaseStudiesElem,
    CaseStudyElem,
    ServicesElem,
    ServiceElem,
    FooterElem,
    ContactElem
  },
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isService", "isCaseStudy"])
  },
  metaInfo() {
    return {
      title: this.page.title || "The Excellence Partnership",
      meta: [
        { name: "description", content: this.page.meta_desc },
        { name: "keywords", content: this.page.meta_keywords }
      ]
    }
  },
  created: function() {
    var route = this.$route
    var url = route.params.url
    var promises = []
    var store = this.$store

    if (!url) store.commit("setPage", {})

    if (route.name == "404") this.page = { main_text: "Sorry, this page cannot be found!" }

    if (route.name == "page-by-url") promises.push(api.getPageByURL(url))
    if (route.name == "root") promises.push(api.getHomePage())

    Promise.all(promises).then(vals => {
      var returnedpage = vals[0]
      store.commit("setPage", returnedpage)

      if (!returnedpage || !returnedpage.ItemID)
        store.commit("setPage", { main_text: "Sorry, this page cannot be found!" })
    })

    store.dispatch("getSettings")
    store.dispatch("getMainPages")
  }
}
</script>
