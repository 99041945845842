<template>
  <section :id="page.ItemID" class="team content">
    <div class="inner">
      <h2>The Team</h2>
      <div class="grid">
        <a :key="profile._id" v-for="(profile, i) in page.team" href @click.prevent="showModal(i)" class="link"
          ><img :src="profile.image" :alt="profile.name" /><span class="name">{{ profile.name }}</span
          ><span class="role">{{ profile.role }}</span></a
        >
      </div>
      <div class="actions">
        <a href="#header" class="back">&lt; Back to Main Menu</a>
      </div>
    </div>
    <div class="modal-wrapper" :class="show_modal ? 'modal-display' : ''">
      <div class="modal">
        <profile-elem
          :name="active_profile.name"
          :role="active_profile.role"
          :image="active_profile.image"
          :bio="active_profile.bio"
        />
        <div class="actions">
          <a href @click.prevent="show_modal = false" class="back">&lt; Back to the Team</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProfileElem from "@/components/Profile.vue"

export default {
  props: ["profiles"],
  components: {
    ProfileElem
  },
  data: function () {
    return {
      show_modal: false,
      active_profile: {}
    }
  },
  computed: {
    page() {
      return this.$store.state.teamPage || {}
    }
  },
  methods: {
    showModal: function (i) {
      this.active_profile = this.page.team[i]
      this.show_modal = true
    }
  }
}
</script>

<style scoped>
.team {
  color: var(--primary-color);
  background: var(--secondary-color);
  padding-top: 8rem;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 12rem;
  row-gap: 5rem;
  margin: 4rem 0;
  text-align: center;
}

.link img {
  width: 100%;
}

.link span {
  display: block;
}

.actions {
  padding-top: 2rem;
  margin-bottom: 8rem;
}

.modal .actions {
  padding-top: 0;
  margin-bottom: 0;
}

.name {
  font-size: 2rem;
  color: #333;
  margin: 1rem 0;
}

.role {
  font-size: 3rem;
  line-height: 1.1;
  padding: 0 2rem;
}

@media screen and (max-width: 1090px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 6rem;
  }
  .role {
    font-size: 3rem;
  }
}
@media screen and (max-width: 600px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
  }
  .role {
    font-size: 2.5rem;
    padding: 0;
  }
  .actions {
    margin-bottom: 4rem;
  }
}
</style>
