<template>
  <section :id="page.ItemID" class="contact content">
    <div class="inner">
      <div class="grid">
        <div>
          <h2>Contact Us</h2>
          <span v-html="page.main_text"></span>
        </div>
        <div>
          <template v-if="!success">
            <form data-netlify="true" data-netlify-honeypot="bot-field" method="post" name="contact-form">
              <input name="form-name" type="hidden" value="contact-form" />
              <div class="padded">
                <div class="row"><input required name="name" type="text" class="txt" placeholder="Name" /></div>
                <div class="row"><input required name="company" type="text" class="txt" placeholder="Company" /></div>
                <div class="row">
                  <input required name="telephone" type="text" class="txt" placeholder="Telephone" />
                </div>
                <div class="row"><input required name="email" type="email" class="txt" placeholder="Email" /></div>
                <div class="row"><textarea required name="message" class="txt" placeholder="Message"></textarea></div>
              </div>
              <div class="error" v-if="error">Error message here</div>
              <div class="actions">
                <button type="submit" class="meet">Submit</button>
                <a href="#header" class="back">&lt; Back to Main menu</a>
              </div>
            </form>
          </template>
          <div v-else>
            <div class="success">Thank you for your message, we'll be in touch as soon as possible</div>
            <div class="actions">
              <a href="#header" class="back">&lt; Back to Main menu</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      success: false,
      error: false
    }
  },
  computed: {
    page() {
      return this.$store.state.contactPage || {}
    }
  }
}
</script>

<style scoped>
.contact {
  color: var(--primary-color);
  background: var(--secondary-color);
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12rem;
  margin: 8rem auto;
}

div >>> .tel {
  font-size: 4rem;
}
div >>> .email {
  font-size: 2.5rem;
}

div >>> .legal {
  font-size: 1.6rem;
  margin-top: 3rem;
}

div >>> .txt {
  width: 100%;
  border: 0;
  margin-bottom: 1rem;
  padding: 1rem;
  background: #fff;
}

.txt::placeholder {
}

textarea.txt {
  height: 12rem;
}

.error {
  color: #cc0000;
}

.error,
.success {
  display: block;
  clear: both;
}

@media screen and (max-width: 1090px) {
  .grid {
    grid-template-columns: 1fr;
    margin: 4rem auto;
    gap: 0rem;
  }
  .padded {
    padding-top: 0;
  }
}
</style>
