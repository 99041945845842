<template>
  <section class="profile content">
    <div class="inner">
      <div class="grid">
        <div>
          <img alt="name" :src="image" />
        </div>
        <div>
          <h1>{{ name }}, {{ role }}</h1>
          <div v-html="bio"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["name", "role", "image", "bio"]
}
</script>

<style scoped>
.profile {
  color: var(--primary-color);
}
.grid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 12rem;
  margin: 2rem auto;
}

.padded {
  padding-top: 4rem;
}

@media screen and (max-width: 1090px) {
  .grid {
    grid-template-columns: 1fr;
    margin: 4rem auto;
    gap: 4rem;
  }
  .padded {
    padding-top: 0;
  }
}
</style>
