<template>
  <section :id="page.ItemID" class="expertise content">
    <div class="inner">
      <div class="grid">
        <div></div>
        <div class="flex">
          <div>
            <span v-html="page.main_text"></span>
            <div class="actions">
              <a :href="'#' + contactID" class="meet">Let's Talk</a>
              <a href="#header" class="back">&lt; Back to Main Menu</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    page() {
      return this.$store.state.expertisePage || {}
    },
    contactID() {
      return this.$store.state.contactID
    }
  }
}
</script>

<style scoped>
.expertise {
  background: url(../assets/robot.jpg);
  background-position: left center;
  background-size: cover;

  color: white;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 16rem;
  margin: 8rem auto;
}

.expertise p {
  margin: auto;
}

.actions a {
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

.actions a:hover {
  background: var(--primary-color);
  color: white;
}

@media screen and (max-width: 1500px) {
  .actions {
    padding-bottom: 15rem;
  }
  .actions a {
    border: 2px solid white;
    color: white;
  }
  .expertise {
    background-position: 10% center;
  }
}
@media screen and (max-width: 1090px) {
  .grid {
    grid-template-columns: 1fr;
    gap: 0;
    margin: 4rem auto;
    max-width: none;
  }
  .actions {
    padding-bottom: 35rem;
  }
  .expertise {
    background-color: #133f6e;
    background-position: left bottom;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
}
@media screen and (max-width: 700px) {
  .actions {
    padding-bottom: 20rem;
  }
}
</style>
