<template>
  <section class="intro content">
    <div class="inner">
      <div class="grid">
        <div></div>
        <div class="flex">
          <div v-html="page.main_text"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    page() {
      return this.$store.state.page
    }
  }
}
</script>

<style scoped>
.intro {
  background: #192c55;
  background-image: url(../assets/chess-board.jpg);
  background-size: cover;
  background-position: center left;
  color: white;
  text-align: center;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 16rem;
  margin: 15rem auto;
  max-width: 110rem;
}
.intro >>> h1 {
  font-weight: normal;
  font-size: 7rem;
  line-height: 1.1;
  margin-bottom: 8rem;
}

.intro >>> p {
  margin: auto;
}

@media screen and (max-width: 1090px) {
  .intro {
    background-size: 100% auto;
    background-position: bottom left;
    background-repeat: no-repeat;
  }
  .grid {
    grid-template-columns: 1fr;
    margin: 4rem auto;
    margin-bottom: 40rem;
    max-width: none;
    gap: 0;
  }
  .intro >>> h1 {
    font-size: 4rem;
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 700px) {
  .grid {
    margin-bottom: 20rem;
  }
}
</style>
