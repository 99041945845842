<template>
  <section id="programmes" class="programme content">
    <div class="inner">
      <div class="grid">
        <div class="flex">
          <div v-html="page.main_text"></div>
        </div>
        <div class="bg"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    page() {
      return this.$store.state.programmesPage || {}
    }
  }
}
</script>

<style scoped>
.programme {
  color: #333;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16rem;
  margin: 8rem auto;
}

.bg {
  background-image: url(../assets/machine.jpg);
  background-position: center center;
  background-size: cover;
}

h2,
strong,
.actions a {
  color: var(--primary-color);
}

.actions a:hover {
  color: white;
}

div >>> strong em {
  font-size: 2.7rem;
}

.programme p {
  margin: auto;
}

@media screen and (max-width: 1090px) {
  .grid {
    grid-template-columns: 1fr;
    margin: 4rem auto;
    margin-bottom: 0;
    max-width: none;
    gap: 4rem;
  }
  .bg {
    height: 50rem;
  }
  .inner {
    width: 100%;
    max-width: 100%;
  }
  .flex {
    padding: 0 3%;
  }
  strong em {
    font-size: 2rem;
  }
}
</style>
