<template>
  <section class="casestudy content">
    <div class="inner">
      <div class="grid">
        <div class="bg">
          <div v-html="page.long_title"></div>
          <div class="images">
            <img v-for="(image, i) in page.images" :key="i" :src="image.image" :alt="page.name" />
          </div>
        </div>
        <div class="text">
          <div v-html="page.main_text"></div>
          <div class="actions">
            <router-link to="/" @click.native="scrollFix('#' + contactID)" class="meet">Contact Us</router-link>
            <router-link to="/" @click.native="scrollFix('#' + casestudiesID)" class="back"
              >&lt; Back to Case studies</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
var VueScrollTo = require("vue-scrollto")

var options = {
  container: "body",
  easing: "ease-in",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: function () {
    // scrolling started
  },
  onDone: function () {
    // scrolling is done
  },
  onCancel: function () {
    // scrolling has been interrupted
  },
  x: false,
  y: true
}

export default {
  methods: {
    scrollFix: function (hash) {
      setTimeout(() => VueScrollTo.scrollTo(hash, 0, options), 500)
    }
  },
  computed: {
    page() {
      return this.$store.state.page
    },
    contactID() {
      return this.$store.state.contactID
    },
    casestudiesID() {
      return this.$store.state.casestudiesID
    }
  }
}
</script>

<style scoped>
.casestudy {
  color: var(--primary-color);
}
.bg {
  background: var(--primary-color);
  color: white;
  padding: 4rem;
}

.bg >>> img {
  margin: 1rem 0;
}

.bg >>> p {
  font-size: 3rem;
  margin-bottom: 2rem;
}
.text {
  padding: 4rem 0;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 6rem;
  min-height: 70vh;
}

.padded {
  padding-top: 4rem;
}

.images img:first-child {
  max-width: 50%;
}

@media screen and (max-width: 1090px) {
  .inner {
    max-width: 100%;
    width: 100%;
  }
  .bg {
    padding: 4rem 3%;
  }
  .text {
    padding: 2rem 3%;
  }

  .grid {
    grid-template-columns: 1fr;
    margin: 0 auto;
    gap: 4rem;
  }
  .padded {
    padding-top: 0;
  }
}
</style>
