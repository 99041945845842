<template>
  <section :id="page.ItemID" class="services content">
    <div class="inner">
      <div class="grid">
        <router-link :key="i" :to="service.url" class="link" v-for="(service, i) in page.programmes"
          ><img :src="service.image" :alt="service.name" /><span class="name">{{ service.name }}</span></router-link
        >
      </div>
      <div class="actions">
        <a :href="'#' + contactID" class="meet">Contact Us</a>
        <a href="#header" class="back">&lt; Back to Main Menu</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    page() {
      return this.$store.state.programmesPage || {}
    },
    contactID() {
      return this.$store.state.contactID
    }
  }
}
</script>

<style scoped>
.services {
  color: var(--primary-color);
  background: var(--secondary-color);
  padding-top: 8rem;
}
.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 6rem;
  row-gap: 5rem;
  margin: 4rem 0;
  text-align: center;
}

.actions {
  padding-top: 2rem;
  margin-bottom: 8rem;
}

.link span {
  display: block;
}

.name {
  font-size: 3rem;
  margin: 1rem 0;
  line-height: 1.1;
}

@media screen and (max-width: 1090px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4rem;
  }
  .role {
    font-size: 3rem;
  }
}
@media screen and (max-width: 600px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
  }
  .role {
    font-size: 2.5rem;
  }
  .actions {
    margin-bottom: 4rem;
  }
}
</style>
