<template>
  <section class="service content">
    <div class="inner">
      <div class="grid">
        <div class="bg" :style="{ backgroundImage: 'url(' + page.bg_image + ')' }"></div>
        <div>
          <h1>{{ page.name }}</h1>
          <div v-html="page.main_text"></div>
          <div class="actions">
            <router-link to="/" @click.native="scrollFix('#' + contactID)" class="meet">Contact Us</router-link>
            <router-link to="/" @click.native="scrollFix('#' + servicesID)" class="back"
              >&lt; Back to Services</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
var VueScrollTo = require("vue-scrollto")

var options = {
  container: "body",
  easing: "ease-in",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: function () {
    // scrolling started
  },
  onDone: function () {
    // scrolling is done
  },
  onCancel: function () {
    // scrolling has been interrupted
  },
  x: false,
  y: true
}

export default {
  methods: {
    scrollFix: function (hash) {
      setTimeout(() => VueScrollTo.scrollTo(hash, 0, options), 500)
    }
  },
  computed: {
    page() {
      return this.$store.state.page
    },
    contactID() {
      return this.$store.state.contactID
    },
    servicesID() {
      return this.$store.state.programmesID
    }
  }
}
</script>

<style scoped>
.service {
  color: var(--primary-color);
}
.bg {
  background-size: cover;
  background-position: center center;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 12rem;
  margin: 8rem auto;
  min-height: 60vh;
}
.padded {
  padding-top: 4rem;
}

@media screen and (max-width: 1090px) {
  .grid {
    grid-template-columns: 1fr;
    margin: 4rem auto;
    gap: 4rem;
  }
  .bg {
    height: 35rem;
  }
  .padded {
    padding-top: 0;
  }
}
</style>
