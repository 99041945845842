<template>
  <section :id="page.ItemID" class="about content">
    <div class="inner">
      <div class="grid">
        <div v-html="page.text_1"></div>
        <div>
          <div class="padded" v-html="page.text_2"></div>
          <div class="actions">
            <a :href="'#' + contactID" class="meet">Let's Meet</a>
            <a href="#header" class="back">&lt; Back to Main Menu</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    page() {
      return this.$store.state.aboutPage || {}
    },
    contactID() {
      return this.$store.state.contactID
    }
  }
}
</script>

<style scoped>
.about {
  color: var(--primary-color);
  background: var(--secondary-color);
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12rem;
  margin: 8rem auto;
}

.padded {
  padding-top: 4rem;
}

@media screen and (max-width: 1090px) {
  .grid {
    grid-template-columns: 1fr;
    margin: 4rem auto;
    gap: 0rem;
  }
  .padded {
    padding-top: 0;
  }
}
</style>
